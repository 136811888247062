
import React, { Component } from 'react';

import story1 from '../../img/story1.png'
import story2 from '../../img/story2.png'
import arrow from '../../img/arrow.png'
import logo from '../../img/martal.png'
import foto from '../../img/2.png'
//import "../../css/Products.css"
import TransitionGroup from 'react-addons-transition-group';


export default class Producten extends Component{
    constructor(props) {
        super(props);
        //console.log("Effectief product", this);
      }


    render(){
        var backgroundimage = {
            backgroundImage: 'url('+this.props.img+')'
        }
        return(
                    <div key={this.props.key} ref={c => this.container = c} className="card">
                        <div className="content">
                            <div className="front">
                                <img alt={this.props.type} src={this.props.img} />
                                <div>{this.props.type}</div><img alt={this.props.type + " icon"} className={this.props.blaadje==null || this.props.blaadje=='null'? "hide":"blaadje"} src={this.props.blaadje} />
                            </div>
                            <div className="back">
                            <div>
                            <h2>{this.props.type}</h2>
                            <span className="text">
                            {this.props.uitleg}
                            </span>

                            </div>
                           </div>
                        </div>
                    </div>
        );
    }
}
