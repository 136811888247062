
import React, { Component } from 'react';
import Link from "gatsby-link"

import story1 from '../../img/story1.png'
import story2 from '../../img/story2.png'
import arrow from '../../img/arrow.png'
import logo from '../../img/martal.png'
import foto from '../../img/2.png'
//import "../../css/Products.css"
import TransitionGroup from 'react-addons-transition-group';
import Producten from './Producten.js'

const productenslug = {
  "nl-be": "producten",
  "en-gb": "products",
  "fr-be": "produits"
}

export default class ProductenContainer extends Component{
    constructor(props) {
        super(props);
        
        console.log("productcontainer", this)
      }
      

      componentWillAppear(callback){
      }
      

      componentWillEnter (callback) {
      }

      componentDidEnter (callback){
    
      }
    
      componentWillLeave (callback) {
      }

      componentWillUnmount (callback) {

      }


      componentWillUpdate(callback){

   }

      componentDidUpdate(callback){
  }

      componentDidLeave (callback) {
        this.props.showProducts()
      }
    

    render(){
        var lang = this.props.lang
        return(
          <div  id="producten">
          
          <div ref={c => this.container = c} id="test">
          {
            this.props.data.map(function(product, j){
                    return <a key={j} href={"#"+product.specialicatie.text}><Producten type={product.specialicatie.text} uitleg={product.uitleg.text} img={product.image.url}/></a>
                })
            }   
          </div>
        </div>
        );
    }
}
