import React, { Component } from "react";
import Link from "gatsby-link";
//import '../../css/Header.css';
import story1 from "../img/story1.png";
import story2 from "../img/story2.png";
import arrow from "../img/arrow.png";
import logo from "../img/martal.png";
import foto from "../img/martal-zagerij.jpg";
//import "../../css/Products.css"
import Producten from "../layouts/Products/Producten.js";
import ProductenContainer from "../layouts/Products/ProductenContainer.js";
import Footer from "../Footer.js";
//import '../../css/Footer.css';
import TransitionGroup from "react-addons-transition-group";
import AnimateHeight from "react-animate-height";
import { Helmet } from "react-helmet";
import "../styles/Products.scss";
import DefaultLayout from "../layouts/defaultLayout";

import forte from "../img/forte_logo.png";

var productenData = {
  hardhout: [
    { key: 1, name: "European Oak0" },
    { key: 2, name: "European Oak1" },
    { key: 3, name: "European Oak" },
    { key: 4, name: "European Oak" },
    { key: 5, name: "European Oak" },
    { key: 6, name: "European Oak" },
    { key: 7, name: "European Oak" },
  ],
  massievePanelen: [
    { key: 1, name: "European Oak1" },
    { key: 2, name: "European Oak2" },
    { key: 3, name: "European Oak1" },
    { key: 4, name: "European Oak1" },
    { key: 5, name: "European Oak1" },
    { key: 6, name: "European Oak1" },
    { key: 7, name: "European Oak1" },
  ],
};

const hardhoutTitle = {
  "nl-be": "Hardhout",
  "en-gb": "Hardwood",
  "fr-be": "Bois Durs",
};

const panelenTitle = {
  "nl-be": "Massieve Panelen",
  "en-gb": "Solid Wood Panels",
  "fr-be": "Panneaux massifs collés",
};

const productenslug = {
  "nl-be": "producten",
  "en-gb": "products",
  "fr-be": "produits",
};

const title = {
  "nl-be": "Producten",
  "en-gb": "Products",
  "fr-be": "Produits",
};

const subtitle = {
  "nl-be": "MARTAL HOUTIMPORT",
  "en-gb": "Martal WOOD IMPORT",
  "fr-be": "MARTAL IMPORTATEUR DE BOIS",
};

export default class ProductPage extends Component {
  constructor(props) {
    super(props);
    console.log("hahahahahahahahahahahaha", this);

    /*var path = this.props.location.pathname.indexOf("/")
        var lang = this.props.location.pathname.substr(path+1,5)
        console.log(lang)*/

    var lang = "";

    this.state = {
      shouldShowBox: true,
      lang: lang,
      paginaData: this.props.data,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    /*if(!this.props.productsFetched && !this.props.productPageFetched){
            this.props.dispatch(fetchProductPage());
            this.props.dispatch(fetchProductPageProducts());
          }*/
  }

  handleClick(event) {
    console.log("click");
    this.setState({
      shouldShowBox: true,
      currentWood: event.currentTarget.dataset.type,
      data: productenData[event.currentTarget.dataset.type],
    });
  }

  findCurrentIndex() {
    let index;

    //console.log(this.props.location.pathname.split(`/`)[3])
    return index;
  }

  showProducts() {
    console.log("showproducts");
    this.setState({
      shouldShowBox: true,
      currentWood: this.state.currentWood,
      data: this.state.data,
    });
    console.log(this.state);
  }

  toggleBox = () => {
    console.log(!this.state.shouldShowBox);
    this.setState({
      shouldShowBox: !this.state.shouldShowBox,
      currentWood: this.state.currentWood,
      data: this.state.data,
    });
    console.log(this.state.shouldShowBox);
  };

  renderProdcuts() {
    return this.state.data.map((item) => {
      return <Producten ref={(c) => (this.container = c)} type={item.name} />;
    });
  }

  render() {
    this.findCurrentIndex();
    var data = this.props.data.allPrismicSpecialisaties.edges[0].node.data;
    var shouldShowBox = this.state.shouldShowBox;
    /*if(!this.props.productsFetched && !this.props.productPageFetched){
            return (<div>loading</div>)
        }*/

    var hardhout = [];
    /*for(var i = 0;i<this.props.productPage.body[0].items.length;i++){

            for(var j = 0;j<this.props.products.length;j++){
                console.log(this.props.products[j])
                if(this.props.products[j].uid == this.props.productPage.body[0].items[i].products.uid){
                    hardhout.push(this.props.products[j]);

                }

            }
        }*/
    //console.log(this.props.location.pathname);
    productenData.hardhout = hardhout;
    productenData.massievePanelen = hardhout;

    console.log(this.state);

    return (
      <DefaultLayout data={this.props.data.allPrismicMenu.edges[0].node.data}>
        <Helmet>
          <title>{data.meta_title.text}</title>
          <meta name="description" content={data.meta_description.text} />
        </Helmet>
        <div
          id="aboutHeaderImage"
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(" +
              data.hero_image.url +
              ")",
          }}
        >
          <div id="titleContainer">
            <h1>{data.titel.text}</h1>
            <h2>{data.subtitle.text}</h2>
          </div>
        </div>

        <div className="clear"></div>
        <TransitionGroup component="span">
          {
            <ProductenContainer
              lang={this.state.lang}
              showProducts={this.showProducts.bind(this)}
              data={data.body[0].items}
            />
          }
        </TransitionGroup>
        <footer>
          <div className="footerMenuContainer">
            <div className="menuLeft">
              <div id="logoContainer">
                <img className="logo" src={forte} />
              </div>
              <div className="menuContainer">
                <ul className="menuLinks">
                  <li>
                    <a href="/specialisaties">Specialisaties</a>
                  </li>
                  <li>
                    <a href="/over-ons">Over ons</a>
                  </li>
                  <li>
                    <a href="/over-ons#jobs">Jobs</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="menuRight"></div>
          </div>
          <div className="copyright">
            <div id="gafaslink">
              Website by
              <a href="https://www.gafas.be"> Gafas</a>
            </div>
          </div>
        </footer>
      </DefaultLayout>
    );
  }
}

export const pageQuerySpecialisaties = graphql`
  query specialisaties {
    allPrismicMenu {
      edges {
        node {
          id
          slugs
          lang
          data {
            logo {
              url
            }
            telefoonnummer {
              html
              text
            }
            contact {
              html
              text
            }
            diensten {
              html
              text
            }
            over_ons {
              html
              text
            }
            jobs {
              html
              text
            }
            afspraak {
              html
              text
            }
            body {
              ... on PrismicMenuBodyDiensten {
                items {
                  titel {
                    html
                    text
                  }
                  link {
                    html
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicSpecialisaties {
      edges {
        node {
          id
          slugs
          lang
          data {
            meta_title {
              html
              text
            }
            meta_description {
              html
              text
            }
            titel {
              html
              text
            }
            subtitle {
              html
              text
            }
            hero_image {
              url
            }
            body {
              __typename
              ... on PrismicSpecialisatiesBodySpecialisaties {
                slice_type
                items {
                  specialicatie {
                    html
                    text
                  }
                  uitleg {
                    html
                    text
                  }
                  image {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
